<template>
    <div class="popup_sub_container">
        <div class="address_book_header_wrapper">
            <h1>Inbound</h1>
            <div class="btn-download" @click="downloadReport" ref="download">Download</div>
        </div>
        <div class="address_book_header_wrapper">
            <table class="status-label">
                <thead>
                    <tr>
                        <th>Status:</th>
                        <th>Count: </th>
                    </tr>
                    <tr>
                        <th>{{ status }}</th>
                        <th>{{ bookings.length }}</th>
                    </tr>
                </thead>
            </table>
        </div>
        <div class="address_book_body_wrapper">
            <table>
                <thead>
                    <tr>
                        <td>Booking Ref</td>
                        <td>DATE</td>
                        <td>PERSON</td>
                        <td>NUMBER OF DAYS OUTSTANDING</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v, i) in bookings">
                        <td>{{ v.booking_prefix }}{{ v.booking_id }}</td>
                        <td>{{ v.request_date | formatDate }}</td>
                        <td>{{ v.requester_name }}</td>
                        <td>{{ v.request_date | outStandingDate }}</td>
                    </tr>
                    <tr v-if="!bookings.length">
                        <td colspan="4">
                            <center>No data</center>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <section class="centered addressbook_modal_footer">
            <a class="link_bt bt_cancel" @click="closeModal">Close</a>
        </section>
    </div>
</template>
<script>

import moment from "moment";
export default {
    props: ['bookings','status'],
    data() {
        return {
            api_url: "",
        }
    },
    filters: {
        formatDate(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('DD/MM/YYYY')
        },
        outStandingDate(value) {
            if (!value) {
                return;
            }
            let from = moment(String(value));
            let to = moment();

            return to.diff(from, 'days')
        },
        
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL;
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        downloadReport() {
            let method = "POST";
            let input;
            let form = document.createElement("form");
            form.setAttribute("method", method);
            form.setAttribute(
                "action",
                this.api_url +
                "/api/dashboard/inbound/status/download?token=" +
                localStorage.token
            );
            
            let status = document.createElement("input");
            status.type = "hidden";
            status.name = 'status';
            status.value = this.status;
            form.appendChild(status);
            let allData = document.createElement("input");
            allData.type = "hidden";
            allData.name = 'data';
            allData.value = JSON.stringify(this.bookings);
            form.appendChild(allData);
            this.$refs.download.appendChild(form);
            form.submit();
            this.$refs.download.removeChild(form);
        },
    },
};
</script>
<style scoped>
.btn-download {
    padding: 7px;
    background-color: #06a5ed;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    color: white;
    font-size: 1.25rem;
    height: 40px;
    cursor:pointer;
}
.address_book_body_wrapper table {
    width: 100%;
}

.btn-download {
    padding: 7px;
    background-color: #06a5ed;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    color: white;
    font-size: 1.25rem;
    height: 40px;
    cursor:pointer;
}
.for_inside_addressbookmodal_adj {
    position: relative;
    height: 1px; /*udaipp*/
}
.for_inside_addressbookmodal_adj .add_contact_adj {
    position: absolute;
    z-index: 99;
}
input[type="checkbox"] {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 1px solid #333;
}
.address_book_body_wrapper table tbody tr {
    font-weight: 400;
}
</style>
<style lang="scss">
.popup_sub_container {
    padding: 20px;
    width: 100%;
    margin: auto;
    background: #fff;
}
.popup_sub_container > section {
    padding: 20px 0 0 0;
}
section.centered.addressbook_modal_footer {
    background: #f5f5f5;
    padding: 10px 0;
}
.address_book_header_wrapper {
    background: #f5f5f5;
    padding: 20px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    .status-label {
        width: 100%;
    }
    .text_box_container {
        width: 30%;
        height: 50px;
        input[type="text"] {
            width: 100%;
            height: 50px;
            background: #fff;
            border: 1px solid #ddd;
            padding: 0 20px;
        }
    }
    .address_filter_container {
        width: 25%;
        height: 50px;
        position: relative;
        background: #06a5ed;
        .address_filter_bt {
            width: 100%;
            height: 50px;
            background: #ddd;
            color: #000;
            text-align: center;
            line-height: 50px;
            display: block;
            border: 1px solid #ccc;
        }
        .address_filter_options {
            width: 100%;
            height: auto;
            min-height: 50px;
            background: #fff;
            position: absolute;
            left: 0;
            top: 50px;
            display: none;
            padding: 0 20px;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
            ul li {
                display: block;
                padding: 10px 0;
                label {
                    cursor: pointer;
                }

                input {
                    display: inline-block;
                }
                span {
                    display: inline-block;
                    margin-left: 20px;
                    font-size: 14px;
                    position: relative;
                    top: -4px;
                }
            }
        }
        &:hover .address_filter_options {
            display: block;
        }
    }
    .add_new {
        background: #06a5ed;
        height: 50px;
        line-height: 50px;
        width: 10%;
        text-align: center;
        display: block;
        border: 1px solid #ddd;
    }
}

.address_book_body_wrapper {
    padding-top: 50px;
    height: calc(100vh - 293px);
    overflow: auto;
    border-bottom: 5px solid #ddd;
    table {
        border-collapse: collapse;
        font-size: 14px;
        tr {
            font-weight: bold;
            font-size: 16px;
        }
        td {
            border-bottom: 1px solid #ddd;
            padding: 20px 5px;
            &:first-child {
                min-width: 200px;
            }
            &:last-child {
                min-width: 150px;
            }
            label {
                display: inline-block;
                margin-right: 5px;
            }
            .edit_icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(../../assets/ico_edit.png) no-repeat;
                background-size: cover;
                margin-right: 5px;
            }
            .delete_icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(../../assets/ico_delete.png) no-repeat;
                background-size: cover;
                margin-right: 5px;
            }
        }
    }
}

a.link_bt {
    display: inline-block;
    width: 200px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #333;
    color: #ccc;
    margin: 0 20px;
    font-weight: 600;
    text-transform: uppercase;
}
a.link_bt.bt_cancel {
    background: #000;
    color: #fff;
}
a.link_bt.bt_save {
    background: #004a7c;
    color: #fff;
}

i.closeme {
    width: 30px;
    height: 30px;
    background: #000;
    color: #06a5ed;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    border-radius: 100px;
    display: block;
    font-style: normal;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
</style>