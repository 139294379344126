<template>
  <div class="common_sub_container">
    <div class="dashboard_row">
      <div class="dashboard_block dashboard_block1">
        <loader v-if="loaders.profile_info_loader" size="60" />
        <div class="logo_div" v-if="!loaders.profile_info_loader">
          <img :src="profile.avatar" />
        </div>
        <div class="content_div" v-if="!loaders.profile_info_loader">
          <span class="name">{{profile.name}}</span>
          <span class="phone">
            <i class="material-icons">phone</i>
            {{profile.phone}} / {{profile.mobile}}
          </span>
          <span class="mail">
            <i class="material-icons">mail</i>
            {{profile.email}}
          </span>
          <span class="address">
            <i class="material-icons">location_on</i>
            <em>
              {{profile.address_1}},
              {{profile.address_2}},
              {{profile.city}},{{profile.state}},
              {{profile.suburb}}, {{profile.postal_code}},
              {{profile.country}}
            </em>
          </span>
          <span class="address">
            <i class="material-icons">home</i>
            <em>
              {{profile.cost_name}}
              
            </em>
          </span>
        </div>
      </div>
      <div class="dashboard_block dashboard_block2">
        <h4>Track Shipment</h4>
        <div class="logo_row">
          <div class="logo_div">
            <img src="../../assets/dash_logo.png" />
          </div>
          <div class="search_div">
            <input
              type="text"
              v-model="bookingId"
              placeholder="Search here"
              @keyup.enter="$refs.search_booking.click()"
              @keydown="nameKeydown($event)"
            />
            <a class="bt_search" ref="search_booking" @click="submit">Search</a>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard_row">
      <div class="second_row_cover1">
        <template v-if="'super_admin' != auth().user_type">
        <div class="dashboard-row-divider">
          <div class="dashboard_block dashboard_block3">
            <loader v-if="loaders.booking_reports_loader" size="60" />
            <center v-if="!loaders.booking_reports_loader"><h4>INBOUND</h4></center>
            <div class="pending_blocks_row" v-if="!loaders.booking_reports_loader">
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader"
                @click="redirectTo('/pages/ViewEncodePending',{jobType:'encode'})"
              >
                <img src="../../assets/dash_encode.png" />
                <p class="total">Total</p>
                <p class="total numbers clr4">{{count.mailroom}}</p>
                <p class="total category">ENCODE</p>
              </div>
              <div
                class="pending_block"
                @click="redirectTo('/pages/ViewPending',{jobType:'Mailroom'})"
              >
                <img src="../../assets/dash_mailroom.png" />
                <p class="total">Total</p>
                <p class="total numbers clr1">{{count.mailroom_delivered}}</p>
                <p class="total category">DELIVERED</p>
              </div>
            </div>
          </div>
          <div class="dashboard_block dashboard_block3">
            <loader v-if="loaders.booking_reports_loader" size="60" />
            <center v-if="!loaders.booking_reports_loader"><h4>OUTBOUND</h4></center>
            <div class="pending_blocks_row" v-if="!loaders.booking_reports_loader">
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader"
                @click="redirectTo('/pages/ViewCourierPending',{jobType:'Courier',fromDahbaord:'true'})"
              >
                <img src="../../assets/dash_courier.png" />
                <p class="total">Total</p>
                <p class="total numbers clr2">{{count.courier_booked}}</p>
                <p class="total category">BOOKED</p>
              </div>
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader"
                @click="redirectTo('/pages/ViewPendingTransport',{jobType : 'Transport'})"
              >
                <img src="../../assets/dash_transport.png" />
                <p class="total">Total</p>
                <p class="total numbers clr3">{{count.courier_delivered}}</p>
                <p class="total category">DELIVERED</p>
              </div>
            </div>
          </div>
        </div>
        </template>
        <template v-else>

        <div style="margin-bottom:10px;display: flex;">
          <span>New South Wales Branch</span>
          <div style="margin-left:auto;margin-right:20px;">
            <input type="date" v-model="super_admin_date_filter.nsw" @change="fetchBookingCount">
          </div>
        </div>
        <div class="dashboard-row-divider">
          <div class="dashboard_block dashboard_block3" style="margin-bottom:20px;">
            <loader v-if="loaders.booking_reports_loader" size="60" />
            <center v-if="!loaders.booking_reports_loader"><h4>INBOUND</h4></center>
            <div class="pending_blocks_row" v-if="!loaders.booking_reports_loader">
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader"
                @click="redirectTo('/pages/ViewEncodePending',{jobType:'encode'})"
              >
                <img src="../../assets/dash_encode.png" />
                <p class="total">Total</p>
                <p class="total numbers clr4">{{count.mailroom}}</p>
                <p class="total category">ENCODE</p>
              </div>
              <div
                class="pending_block"
                @click="redirectTo('/pages/ViewPending',{jobType:'Mailroom'})"
              >
                <img src="../../assets/dash_mailroom.png" />
                <p class="total">Total</p>
                <p class="total numbers clr1">{{count.mailroom_delivered}}</p>
                <p class="total category">DELIVERED</p>
              </div>
            </div>
          </div>
          <div class="dashboard_block dashboard_block3" style="margin-bottom:20px;">
            <loader v-if="loaders.booking_reports_loader" size="60" />
            <center v-if="!loaders.booking_reports_loader"><h4>OUTBOUND</h4></center>
            <div class="pending_blocks_row" v-if="!loaders.booking_reports_loader">
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader"
                @click="redirectTo('/pages/ViewCourierPending',{jobType:'Courier',fromDahbaord:'true'})"
              >
                <img src="../../assets/dash_courier.png" />
                <p class="total">Total</p>
                <p class="total numbers clr2">{{count.courier_booked}}</p>
                <p class="total category">BOOKED</p>
              </div>
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader"
                @click="redirectTo('/pages/ViewPendingTransport',{jobType : 'Transport'})"
              >
                <img src="../../assets/dash_transport.png" />
                <p class="total">Total</p>
                <p class="total numbers clr3">{{count.courier_delivered}}</p>
                <p class="total category">DELIVERED</p>
              </div>
            </div>
          </div>
        </div>
        <div  style="margin-bottom:10px;display: flex;">
          <span>Victoria Branch</span>
          <div style="margin-left:auto;margin-right:20px;">
            <input type="date" v-model="super_admin_date_filter.vic" @change="getVicCounts">
          </div>
        </div>
        <div class="dashboard-row-divider">
          <div class="dashboard_block dashboard_block3">
            <loader v-if="loaders.booking_reports_loader_vic" size="60" />
            <center v-if="!loaders.booking_reports_loader_vic"><h4>INBOUND</h4></center>
            <div class="pending_blocks_row" v-if="!loaders.booking_reports_loader_vic">
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader_vic"
                @click="redirectTo('/pages/ViewEncodePending',{jobType:'encode'})"
              >
                <img src="../../assets/dash_encode.png" />
                <p class="total">Total</p>
                <p class="total numbers clr4">{{vic_count.mailroom}}</p>
                <p class="total category">ENCODE</p>
              </div>
              <div
                class="pending_block"
                @click="redirectTo('/pages/ViewPending',{jobType:'Mailroom'})"
              >
                <img src="../../assets/dash_mailroom.png" />
                <p class="total">Total</p>
                <p class="total numbers clr1">{{vic_count.mailroom_delivered}}</p>
                <p class="total category">DELIVERED</p>
              </div>
            </div>
          </div>
          <div class="dashboard_block dashboard_block3">
            <loader v-if="loaders.booking_reports_loader_vic" size="60" />
            <center v-if="!loaders.booking_reports_loader_vic"><h4>OUTBOUND</h4></center>
            <div class="pending_blocks_row" v-if="!loaders.booking_reports_loader_vic">
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader_vic"
                @click="redirectTo('/pages/ViewCourierPending',{jobType:'Courier',fromDahbaord:'true'})"
              >
                <img src="../../assets/dash_courier.png" />
                <p class="total">Total</p>
                <p class="total numbers clr2">{{vic_count.courier_booked}}</p>
                <p class="total category">BOOKED</p>
              </div>
              <div
                class="pending_block"
                v-if="!loaders.booking_reports_loader_vic"
                @click="redirectTo('/pages/ViewPendingTransport',{jobType : 'Transport'})"
              >
                <img src="../../assets/dash_transport.png" />
                <p class="total">Total</p>
                <p class="total numbers clr3">{{vic_count.courier_delivered}}</p>
                <p class="total category">DELIVERED</p>
              </div>
            </div>
          </div>
        </div>
        </template>
        <div class="dashboard_block dashboard_block4">
          <div class="dashboard_header">
            <h4>Booking Reports</h4>

              <select v-model="chart_filter" @change="generateChart">
              <option value="all">All</option>
              <option value="courier">Courier</option>
              <option value="messenger">Messenger</option>
              <option value="encode">Encode</option>
              <option value="mailroom">Mailroom</option>
              <option value="transport">Transport</option>
            </select>

            <select v-model="date_filter" @change="generateChart">
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="lastweek">Last Week</option>
              <option value="lastmonth">Last Month</option>
            </select>

              <select v-model="carrier_filter" @change="generateChart">
              <option value="all">All Carriers</option>
              <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
            </select>

          

           
          </div>
          <div class="chart_container">
            <loader v-if="loaders.chart_loader" />
            <line-chart :chartdata="chart_data" :options="options" v-if="!loaders.chart_loader" />
          </div>
        </div>
      </div>
      <div class="second_row_cover2">
        <div class="dashboard-row-divider"  v-if="'hub_admin' == auth().user_type">
            <div class="dashboard_block dashboard_block3">
              <loader v-if="loaders.inbound_status" size="60" />
              <center v-if="!loaders.inbound_status"><h4>Inbound Statuses</h4></center>
              <div class="pending_blocks_row" v-if="!loaders.inbound_status">
                <table class="inbound-statuses">
                  <thead>
                    <tr>
                      <th>Current Status</th>
                      <th>Numbers</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr @click="showDetail(inbound_status.allocated, 'Allocated')">
                      <td class="label">ALLOCATED</td>
                      <td>{{ inbound_status.allocated.length }}</td>
                    </tr>
                    <tr @click="showDetail(inbound_status.dispatched, `Dispatched`)">
                      <td class="label">DISPATCHED</td>
                      <td>{{ inbound_status.dispatched.length }}</td>
                    </tr>
                    <tr @click="showDetail(inbound_status['scan run'], `Scan Run`)">
                      <td class="label">SCAN RUN</td>
                      <td>{{ inbound_status['scan run'].length }}</td>
                    </tr>
                    <tr @click="showDetail(inbound_status.returned, `Returned`)">
                      <td class="label">RETURNED</td>
                      <td>{{ inbound_status.returned.length }}</td>
                    </tr>
                    <tr @click="showDetail(inbound_status['not available'], `Not Available`)">
                      <td class="label">NOT AVAILABLE</td>
                      <td>{{ inbound_status['not available'].length }}</td>
                    </tr>
                    <tr @click="showDetail(inbound_status['refused delivery'], `Refused Delivery`)">
                      <td class="label">REFUSED DELIVERY</td>
                      <td>{{ inbound_status['refused delivery'].length }}</td>
                    </tr>
                    <tr @click="showDetail(inbound_status['return to mail room'], `Return to Mail Room`)">
                      <td class="label">RETURN TO MAIL ROOM</td>
                      <td>{{ inbound_status['return to mail room'].length }}</td>
                    </tr>
                    <tr @click="showDetail(inbound_status['return to print room'], `Return to Print Room`)">
                      <td class="label">RETURN TO PRINT ROOM</td>
                      <td>{{ inbound_status['return to print room'].length }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
        <div v-if="'super_admin' != auth().user_type" class="dashboard_block dashboard_block5" :style="'hub_admin' != auth().user_type ? 'height:735px':''" >
          <h4>Recent Activities</h4>
          <loader v-if="loaders.recent_activities_loader" size="60" />
          <div class="activity_list" v-if="!loaders.recent_activities_loader">
            <div class="activity_block" v-for="(recent,i) in recents" :key="i">
              <div
                class="activity_time"
              >{{ moment(recent.created_at, "DDMMYYYY h:mm:ss").fromNow() }}</div>
              <div class="activity">{{recent.description}}</div>
            </div>
          </div>
        </div>
        <div  v-if="'super_admin' == auth().user_type" class="dashboard_block dashboard_block5" style="height:1140px;margin-top:30px;" >
          <h4>Recent Activities</h4>
          <loader v-if="loaders.recent_activities_loader" size="60" />
          <div class="activity_list" v-if="!loaders.recent_activities_loader">
            <div class="activity_block" v-for="(recent,i) in recents" :key="i">
              <div
                class="activity_time"
              >{{ moment(recent.created_at, "DDMMYYYY h:mm:ss").fromNow() }}</div>
              <div class="activity">{{recent.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
		<div class="black-overlay" v-show="show_inbound_status">
			<div class="box-modal printPops">
				<div class="body">
					<InboundStatuses @closeModal="closeInboundStatus" :bookings="selected_inbound"
						:status="selected_status" v-if="show_inbound_status" />
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import LineChart from "./LineChart";
import InboundStatuses from "./InboundStatuses";
import moment from "moment"
export default {
  name: "dashboard",
  components: { 
    LineChart,
    InboundStatuses,
  },
  data() {
      
    return {
      profile: {
        name: "",
        email: "",
        company: "",
        branch: "",
        phone: "",
        cost_center_id: "",
        avatar: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        suburb: "",
        postal_code: "",
        country: "",
        monthly_budget: "",
        transport_budget: ""
      },
      super_admin_date_filter:{
        nsw:moment(new Date()).format("YYYY-MM-DD"),
        vic:moment(new Date()).format("YYYY-MM-DD"),
      },
      count: {
        mailroom: 0,
        courier: 0,
        encode: 0,
        transport: 0,
        mailroom_delivered:0,
        courier_booked:0,
        courier_delivered:0,
      },
      vic_count: {
        mailroom: 0,
        courier: 0,
        encode: 0,
        transport: 0,
        mailroom_delivered:0,
        courier_booked:0,
        courier_delivered:0,
      },
      count_data:[],
      cost_data:[],
      bookingId: "",
      recents: [],
      chart_filter: "all",
      date_filter:"today",
      carrier_filter:"all",
      chart_data: {
        labels: [],
        datasets: [
          {
            label: "Cost",
            data: [],
            backgroundColor: "rgb(255,207,1)",
            borderColor: "rgb(255,207,1)",
            pointBorderColor: "#000",
            borderWidth: 1
          },
          //   {
          //   label: "Courier Monthly Budget",
          //   data: [],
          //   backgroundColor: "rgba(0,255,0,0.6)",
          //   borderColor: "green",
          //   borderWidth: 1
          // },

            {
            label: "Carrier Budget",
            data: [],
            backgroundColor: "rgb(6,165,237)",
            borderColor: "rgb(6,165,237)",
            borderWidth: 1
          },
          {
            label: "Transport Monthly Budget",
            data: [],
            backgroundColor: "rgb(73,169,66)",
            borderColor: "rgb(73,169,66)",
            borderWidth: 1
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
              label: (tooltipItem) =>  {
                return `No of Bookings : ${this.count_data[tooltipItem.index]} , Cost : ${this.cost_data[tooltipItem.index]} , Total Cost : ${this.cost_data.slice(0,tooltipItem.index + 1).reduce((a,b) => a + b,0)}`;
              }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                }
              }
            }
          ]
        },
        annotation: {
          drawTime: 'afterDatasetsDraw',
          annotations: []
        },
        responsive: true,
        maintainAspectRatio: false
      },
      loaders: {
        profile_info_loader: true,
        courier_count_loader: true,
        recent_activities_loader: true,
        booking_reports_loader: true,
        booking_reports_loader_vic: true,
        chart_loader: true,
        inbound_status: true
      },
      show_inbound_status:false,
      inbound_status: {
        allocated: [],
        dispatched: [],
        returned: [],
        "scan run":[],
        "not available":[],
        "refused delivery":[],
        "return to mail room":[],
        "return to print room":[],
      },
      selected_inbound: {},
      selected_status: {},
      carriers: []
    };
  },
  async created() {
    this.$emit("breadCrumbData", []);
    this.loading = true;
    await this.fetchUserData();
    this.fetchBookingCount();
    this.recentActivity();
    this.generateChart();
    this.fetchCarriers();
    
    if ('hub_admin' == this.auth().user_type) {
      this.inboundStatus();
    }
    if ('super_admin' == this.auth().user_type) {
      this.getVicCounts();
    }
  },
  methods: {
    closeInboundStatus() {
      this.show_inbound_status = false
    },
    closeInboundStatus() {
      this.show_inbound_status = false
    },
    showDetail(datas, status) {
      this.selected_inbound = datas
      this.selected_status = status
      this.show_inbound_status = true
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    async inboundStatus() {
      this.loaders.inbound_status = true;
      
      this.loaders.inbound_status = true;
      try {
        let response = await this.axios.get("/api/dashboard/inbound/status");
        this.inbound_status = response.data.list
      } catch (e) {
        console.log(e);
      }
      this.loaders.inbound_status = false;
    },
    async fetchUserData() {
      this.loaders.profile_info_loader = true;
      try {
        let response = await this.axios.get("/api/dashboard/user/data");
        this.profile.name = response.data.msg.name;
        this.profile.email = response.data.msg.email;
        this.profile.phone = response.data.msg.phone;
        this.profile.mobile = response.data.msg.mobile;
        this.profile.address_1 = response.data.msg.address_1;
        this.profile.address_2 = response.data.msg.address_2;
        this.profile.city = response.data.msg.city;
        this.profile.state = response.data.msg.state;
        this.profile.suburb = response.data.msg.suburb;
        this.profile.postal_code = response.data.msg.postal_code;
        this.profile.country = response.data.msg.country;
        this.profile.avatar = response.data.msg.avatar;
        this.profile.cost_name = response.data.msg.cost ? response.data.msg.cost.name : '';
        this.profile.monthly_budget = response.data.msg.monthly_budget;
        this.profile.transport_budget = response.data.msg.transport_budget;
      } catch (e) {
        console.log(e);
      }
      this.loaders.profile_info_loader = false;
    },
    async recentActivity() {
      this.loaders.recent_activities_loader = true;
      try {
        let response = await this.axios.get("/api/dashboard/user/recent");
        this.recents = response.data.msg;
      } catch (e) {
        console.log(e);
      }
      this.loaders.recent_activities_loader = false;
    },
    redirectTo(to, data) {
      if (this.auth().user_type != "customer_service_user") {
        localStorage.viewPending = JSON.stringify(data);
        this.$router.push(to);
      }
    },
    async fetchBookingCount() {
      this.loaders.booking_reports_loader = true;
      let url = "/api/dashboard/user/bookingCount"

      if ('super_admin' == this.auth().user_type) {
        url += `?date=${this.super_admin_date_filter.nsw}`
      }

      try {
        let response = await this.axios.get(url);
        this.count.mailroom = response.data.MailRoom;
        this.count.mailroom_delivered = response.data.mailroom_delivered;
        this.count.courier_delivered = response.data.courier_delivered;
        this.count.courier_booked = response.data.courier_booked;
        this.count.courier = response.data.Courier;
        this.count.encode = response.data.Encode;
        this.count.transport = response.data.Transport;
      } catch (e) {
        console.log(e);
      }
      this.loaders.booking_reports_loader = false;
    },
    async getVicCounts() {
      this.loaders.booking_reports_loader_vic = true;
      
      let url = "/api/dashboard/user/bookingCount/branches"
      try {
        if ('super_admin' == this.auth().user_type) {
          url += `?date=${this.super_admin_date_filter.vic}`
        }
        let response = await this.axios.get(url);
        this.vic_count.mailroom = response.data.MailRoom;
        this.vic_count.mailroom_delivered = response.data.mailroom_delivered;
        this.vic_count.courier_delivered = response.data.courier_delivered;
        this.vic_count.courier_booked = response.data.courier_booked;
        this.vic_count.courier = response.data.Courier;
        this.vic_count.encode = response.data.Encode;
        this.vic_count.transport = response.data.Transport;
      } catch (e) {
        console.log(e);
      }
      this.loaders.booking_reports_loader_vic = false;
    },
    async fetchCarriers() {
      this.axios.get("api/provider").
      then(response => {
        this.carriers = response.data.providers;
      })
      .catch(error => {
        console.log(error);
      })
    },

    async generateChart() {
      this.loaders.chart_loader = true;
      let response = await this.axios.get("api/chart/" + this.chart_filter+ '/'+ this.date_filter+'/'+ this.carrier_filter);
      this.monthly_budget=response.data.monthly_budget;

      try {
        let dates = [
          this.moment()
            .subtract(6, "d")
            .format("YYYY-MM-DD"),
          this.moment()
            .subtract(5, "d")
            .format("YYYY-MM-DD"),
          this.moment()
            .subtract(4, "d")
            .format("YYYY-MM-DD"),
          this.moment()
            .subtract(3, "d")
            .format("YYYY-MM-DD"),
          this.moment()
            .subtract(2, "d")
            .format("YYYY-MM-DD"),
          this.moment()
            .subtract(1, "d")
            .format("YYYY-MM-DD"),
          this.moment().format("YYYY-MM-DD")
        ];
        let data = {};
        response.data.data.forEach(element => {
          data[element.request_date] = {
            cost:element.cost,
            count:element.count
          };
        });
        this.chart_data.labels = dates.map(element => {
          return this.moment(element).format("dddd");
        });
        this.options.annotation.annotations = [];
        if (this.chart_filter == "all") {
          this.options.annotation.annotations.push(this.generateLabelContent("rgb(73,169,66)","Transport Monthly Budget",this.profile.transport_budget));
          this.options.annotation.annotations.push(this.generateLabelContent("rgb(6,165,237)","Courier Monthly Budget",this.monthly_budget));
          //this.options.annotation.annotations.push(this.generateLabelContent("green","Courier Monthly Budget",this.profile.monthly_budget));
        }
        else if(this.chart_filter == "transport"){
          this.options.annotation.annotations.push(this.generateLabelContent("rgb(73,169,66)","Transport Monthly Budget",this.profile.transport_budget));
        }
        else if(this.chart_filter == "courier"){
          //this.options.annotation.annotations.push(this.generateLabelContent("green","Courier Monthly Budget",this.profile.monthly_budget));
            this.options.annotation.annotations.push(this.generateLabelContent("rgb(6,165,237)","Courier Monthly Budget",this.monthly_budget));
        }

          else if(this.chart_filter == "messenger"){
          //this.options.annotation.annotations.push(this.generateLabelContent("green","Courier Monthly Budget",this.profile.monthly_budget));
            this.options.annotation.annotations.push(this.generateLabelContent("rgb(6,165,237)","Courier Monthly Budget",this.monthly_budget));
        }

        
        this.count_data = [];
        this.cost_data = [];
        dates.forEach(element => {
          this.count_data.push(data[element] ? data[element].count || 0 : 0);
          this.cost_data.push(data[element] ? data[element].cost || 0 : 0);
        });
        this.chart_data.datasets[0].data = this.cost_data;
      } catch (e) {
        console.log(e);
      }
      this.loaders.chart_loader = false;
    },
    generateLabelContent(color,label,value){
    return {
      type: "line",
      mode: "horizontal",
      scaleID: "y-axis-0",
      value: value,
      borderColor: color,
      borderWidth: 2,
      label: {
        enabled: false,
        content: label
      }
    };
    },
    submit() {
      localStorage.setItem(
        "track_trace_filter",
        JSON.stringify({
          booking_id: this.bookingId
        })
      );
      this.$router.push("/pages/TrackTrace");
    }
  }
};
</script>
<style  lang="scss" scoped>
.dashboard_row {
  width: 100%;
  display: flex;
  padding: 20px 0;
  .dashboard-row-divider{
    display: flex;
    .dashboard_block {
      border-top: 5px solid #06A5ED;
      width: 50%;
      height: 250px;
      margin-right: 20px;
      &.dashboard_block3 {
        border-top: 5px solid #06A5ED;
        width: 100%;
        height: 350px;
        margin-bottom: 40px;
        .pending_blocks_row {
          .inbound-statuses{
            width:100%;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            thead {
              background-color: #004a7c;
              font-weight: bold;
              color:white;
            }
            tbody {
              tr {
                td {
                  padding-left:15px;
                  border: 1px solid black;
                }
                td.label {
                  background-color: #06a5ed;
                  font-weight: bold;
                  color: white;
                }
                td.label:hover {
                  cursor:pointer;
                }
              }
              
              tr:hover {
                  background-color: #94ddff;
                  td.label {
                  background-color: #94ddff;
                    font-weight: bold;
                    color: black;
                  }
              }
            }
          }
        }

      }
    }
  }
  .dashboard_block {
    background: #fff;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 25px;
    border: 1px solid #efefef;
    h4 {
      font-size: 20px;
      font-weight: Normal;
      color: #333;
      padding-bottom: 10px;
    }
    &.dashboard_block1 {
      border-top: 5px solid #06A5ED;
      width: 50%;
      height: 250px;
      margin-right: 20px;

      display: flex;
      .logo_div {
        width: 150px;
        height: 150px;
        flex-shrink: 0;
        background: #ccc;
      }
      .content_div {
        width: 100%;
        height: auto;
        padding: 10px;
        color: #666;
        span {
          display: flex;
          padding-bottom: 10px;
          i {
            position: relative;
            top:-2px;
            color: #004a7c;
          }
          &.name {
            font-weight: bold;
            padding-left: 10px;
            font-size: 18px;
          }
          em {
            display: inline-block;
            font-style: normal;
          }
        }
      }
    }
    &.dashboard_block2 {
      border-top: 5px solid #06A5ED;
      width: 50%;
      height: 250px;
      margin-left: 20px;
      .logo_row {
        display: flex;
        padding: 20px 0;
        height: 155px;
        .logo_div {
          width: 200px;
          height: auto;
          img {
            width: auto;
            height: 100%;
          }
        }
        .search_div {
          min-width: 300px;
          width: calc(100% - 250px);
          height: auto;
          padding: 40px 20px;
          background: #a2aaad ;
          input[type="text"] {
            width: calc(100% - 80px);
            height: 40px;
            background: #fff;
            border: 1px solid #ccc;
            padding: 0 10px;
            display: inline-block;
          }
          .bt_search {
            width: 80px;
            height: 40px;
            background: #004a7c;
            color: #fff;
            display: inline-block;
            text-align: center;
            line-height: 40px;
          }
        }
      }
    }
    &.dashboard_block4 {
      border-top: 5px solid #06A5ED;
      width: 100%;
      height: 350px;
      overflow-x: auto;
      .dashboard_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .chart_container {
        width: 100%;
      }
    }
    &.dashboard_block5 {
      border-top: 5px solid #06A5ED;
      width: 100%;
      height: 350px;
      overflow-y: scroll;
    }
  }
  .second_row_cover1 {
    width: 70%;
    margin-right: 20px;
  }
  .second_row_cover2 {
    width: 30%;
    margin-left: 20px;
  }
  .pending_blocks_row {
    display: flex;
    height: 240px;

    margin: auto;
    justify-content: space-around;
    .pending_block {
      width: 30%;
      height: auto;
      text-align: center;
      position: relative;
      padding: 10px;
      cursor: pointer;
      .overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        font-size: 16px;
        text-align: center;
        color: #fff;
        display: none;
        justify-content: center;
        flex-direction: column;
      }
      &:hover .overlay {
        display: flex;
      }
      img {
        display: block;
        width: 105px;
        height: 105px;
        margin: auto;
      }
      p.total {
        padding: 5px 0;
        text-align: center;
        font-size: 18px;
        &.numbers {
          font-size: 32px;
          font-weight: 600;
          &.clr1 {
            color:#ffcf01;
          }
          &.clr2 {
            color: #06A5ED;
          }
          &.clr3 {
            color: #49a942;
          }
          &.clr4 {
            color: #004a7c;
          }
        }
        &.category {
          font-size: 24px;
          color: #333;
        }
      }
    }
  }
  .activity_list {
    padding: 10px;
    .activity_block {
      padding-bottom: 20px;
      .activity_time {
        color: #aaa;
        font-size: 14px;
        padding-left: 20px;
        background: url(../../assets/bull_dash.png) no-repeat;
        background-position: left 0 top 50%;
      }
      .activity {
        color: #333;
        font-size: 16px;
        padding-left: 20px;
        background: url(../../assets/bull_line.png) repeat-y;
        background-position: left 0 top 50%;
      }
    }
  }
}

#line-chart {
  height: 280px;
}
</style>